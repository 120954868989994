import React, { useEffect } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

function SectionOurProducts() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: 'ease-in-out',
      once: false, // Allows animations every time you scroll to the section
    });
  }, []);

  const cardsData = [
    {
      imgSrc: '../images/product/ongrid-solar.png',
      imgWidth: '224px',
      title: "On Grid Solar",
      text: 'Connects to the local grid, reducing electricity costs by feeding excess energy back for savings through net metering.',
      delay: '100',
    },
    {
      imgSrc: '../images/product/offgrid-solar.png',
      imgWidth: '224px',
      title: 'Off Grid Solar',
      text: 'Provides complete energy independence with battery storage, perfect for remote areas or those seeking self-sufficiency.',
      delay: '200',
    },
    {
      imgSrc: '../images/product/inverter.png',
      imgWidth: '224px',
      title: 'Inverter & Batteries',
      text: 'Ensures efficient energy conversion and storage, offering continuous power even during outages or nighttime.',
      delay: '300',
    },
    {
      imgSrc: '../images/product/water-heater.png',
      imgWidth: '224px',
      title: 'Solar Water Heaters',
      text: 'Uses solar energy to heat water, reducing utility bills and promoting a greener lifestyle.',
      delay: '400',
    },
  ];

  return (
    <section className='pb-5'>
      <Container>
        <div className='text-center py-5'>
          <h2 className='fw-bold mb-3'>Our Products</h2>
          <h6 className=''>
            We adopt an individual approach with each and every client, and our
            business model is built on the following key principles. See how we
            can help a business like yours today.
          </h6>
        </div>
        <Row>
          {cardsData.map((card, index) => (
            <Col
              lg={3}
              md={6}
              className='mb-4'
              data-aos="zoom-in-up"
              data-aos-delay={card.delay}
              key={index}
            >
              <Card className='w-100 h-100'>
                <Card.Body className='d-flex flex-column align-items-center text-center'>
                  <img
                    src={card.imgSrc}
                    alt={card.title}
                    className='mb-3 mt-4'
                    style={{ width: card.imgWidth }}
                  />
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>{card.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default SectionOurProducts;
