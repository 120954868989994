import React, { useEffect } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

function SectionImportant() {
  useEffect(() => {
    AOS.init({
      duration:600,
      easing: 'ease-in-out',
      once: false, // Allows animations every time you scroll to the section
    });
  }, []);

  const cardsData = [
    {
      imgSrc: '../images/icon-eco-home.svg',      
      title: "Solar Energy's Eco-Advantages",
      text: 'Choosing solar power: a green choice for the environment',
      delay: '100',
    },
    {
      imgSrc: '../images/icon-solar-system.svg',
      title: 'Decrease Electricity Loss with Solar',
      text: 'Experience reduced electricity loss with efficient solar solutions',
      delay: '200',
    },
    {
      imgSrc: '../images/icon-energy-sources.svg',      
      title: 'Unlock Free Energy with Solar Power',
      text: 'Unlock the power of free, renewable energy with solar solutions.',
      delay: '300',
    },
    {
      imgSrc: '../images/icon-renewable-energy.svg',      
      title: 'Cost-Effective Solar Solutions',
      text: 'Benefit from low solar power costs for sustainable energy savings.',
      delay: '400',
    },
  ];

  return (
    <section className='pb-5 bg-gradient-1' style={{marginTop:"-50px"}}>
      <Container>
        <div className='text-center py-5 text-white'>
          <h2 className='fw-bold mb-3'>Why is Solar Energy Important</h2>
          <h6>
            We adopt an individual approach with each and every client, and our
            business model is built on the following key principles. See how we
            can help a business like yours today.
          </h6>
        </div>
        <Row>
          {cardsData.map((card, index) => (
            <Col
              lg={3}
              md={6}
              className='mb-4'
              data-aos='flip-left'
              data-aos-delay={card.delay}
              key={index}
            >
              <Card className='w-100 h-100'>
                <Card.Body className='d-flex flex-column align-items-center text-center'>
                  <div className='box-icon'>
                  <img
                    src={card.imgSrc}
                    alt={card.title}
                  />
                  </div>
                  <Card.Title>{card.title}</Card.Title>
                  <Card.Text>{card.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default SectionImportant;
