import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'

function Footer() {
  return (
    
  <footer className="footer-section">
    <div className='bg-gradient-1'>
    <Container>
       <div className='py-4 text-white'>
        <Row className='align-items-center text-center'>
            <Col xl={12}>
            <h1 className='fw-bold text-white'>PM SURYA GHAR</h1>
            <h3 className='fw-bold text-warning' style={{lineHeight: 'initial'}}>ഇനി എല്ലാ വീട്ടിലും സൗജന്യ വൈദ്യുതി 78000/- രൂപ സബ്സിഡിയോടുകൂടി</h3>
            <h3>Call +91 8075312493, +91 8848694177</h3>
            <h6 className='fw-light'>Ready to make the switch to solar? Contact AVS Solar today to schedule a consultation and discover how we can help you harness the power of the sun to power your home or business efficiently and sustainably.</h6>
            </Col>
           
        </Row>
    </div> 
     </Container>
  </div> 
  <div className="container">
            
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href="index.html"><img src="../images/logo.png" style={{ width: '110px' }} /></a>
                            </div>
                            <div className="text-white">
                                <p>At AVS Solar, we are committed to revolutionizing the way energy is harnessed and utilized. As a leading provider of solar solutions, we specialize in designing, installing, and maintaining state-of-the-art solar power systems for residential, commercial, and industrial clients.</p>
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-3 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Company</h3>
                            </div>
                           
                            <div>
                                <ul className='text-white d-flex flex-column p-0'>
                                    <li><a>Home</a></li>
                                    <li><a>About Us</a></li>
                                    <li><a>Why</a></li>
                                    <li><a>Products</a></li>
                                    <li><a>Services</a></li>
                                    <li><a>Our Works</a></li>
                                    <li><a>Contact</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-6 mb-50">
                        <div >
                             <div className="footer-widget-heading">
                                <h3>Contact</h3>
                            </div>
                            <div>
                            <div className="text-white">
                                <div>TC 53/312-4, Sanchari, Mankulam Lane, Estate Road, Papppanamcode P O, Thiruvananthapuram, Kerala - 695 018</div>
                                <div>Mob: +91 8075312493, 8848694177</div>
                                <div>E-mail: avssolarsolutions@gmail.com</div>
                            </div>
                        </div>
                        <Card className='mt-3' style={{width:'250px', padding:'0 15px'}}>
                        <div className='d-flex align-items-center'>
                            <img src="../images/kseb_logo.png" style={{ width: '100px' }} />
                            <img src="../images/mnre-_logo.png" style={{ width: '100px' }} />
                        </div>
                        </Card>       
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6  text-lg-left">
                        <div className="copyright-text">
                            <p>&copy; 2024 AVS Solar, All Right Reserved </p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </footer>

  )
}

export default Footer