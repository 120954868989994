import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const NAVBAR_HEIGHT = 60;

function MenuNavBar() {
    const [activeLink, setActiveLink] = useState("home");

    const handleScroll = () => {
        const sections = ["home", "about", "why", "ourProducts", "what_we_do", "gallery",  "contact"];
        const scrollPosition = window.scrollY + NAVBAR_HEIGHT + 1; // +1 to ensure it's within the section

        sections.forEach((section) => {
            const sectionElement = document.getElementById(section);
            if (sectionElement) {
                const sectionTop = sectionElement.offsetTop;
                const sectionHeight = sectionElement.offsetHeight;
                if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
                    setActiveLink(section);
                }
            }
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Navbar expand="lg" className="position-fixed w-100 z-3 navbar-bg navbar-nav p-0 shadow-lg">
            <Container>
                <Navbar.Brand href="/"><img src="../images/logo.png" style={{ width: '90px' }} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" style={{ flexGrow: 0 }}>
                    <Nav className="justify-content-end fs-6 d-flex align-items-center">
                        <Nav.Link
                            as={ScrollLink}
                            to="home"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "home" ? "active" : ""}
                        >
                            Home
                        </Nav.Link>
                        <Nav.Link
                            as={ScrollLink}
                            to="about"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "about" ? "active" : ""}
                        >
                            About Us
                        </Nav.Link>
                        <Nav.Link
                            as={ScrollLink}
                            to="why"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "why" ? "active" : ""}
                        >
                            Why
                        </Nav.Link>
                        <Nav.Link
                            as={ScrollLink}
                            to="ourProducts"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "ourProducts" ? "active" : ""}
                        >
                            Products
                        </Nav.Link>
                        <Nav.Link
                            as={ScrollLink}
                            to="what_we_do"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "what_we_do" ? "active" : ""}
                        >
                            Services
                        </Nav.Link>
                        <Nav.Link
                            as={ScrollLink}
                            to="gallery"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "gallery" ? "active" : ""}
                        >
                            Our Works
                        </Nav.Link>
                        
                        <Nav.Link
                            as={ScrollLink}
                            to="contact"
                            smooth={true}
                            duration={500}
                            offset={-NAVBAR_HEIGHT}
                            className={activeLink === "contact" ? "active" : ""}
                        >
                            Contact
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MenuNavBar;
