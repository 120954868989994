import React from 'react'
import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';

function Banner() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
      setIndex(selectedIndex);
    };
  return (
    <div className='banner-bg'>
        <div className='h-100 banner-cover'>
        <Carousel activeIndex={index} onSelect={handleSelect} className="h-100">
      <Carousel.Item className='h-100'>
      <section class="bannerArea h-100 d-flex align-items-center justify-content-center">
                    <div class="container custom-container">
                        <div class="row d-flex align-items-center">
                            <div class="col-xl-8 order-2 order-xl-0">
                                <div class="banner-wrapper" data-aos="fade-up">
                                    <h1 class="banner-title text-white">
                                    Elevate Your Energy Future with Solar Power
                                    </h1>
                                    <h4 class="text-white mb-4 mt-1 fw-light">
                                    Discover the Future of Energy Efficiency Transform Your Home with Sustainable Solar Power Join the Renewable Energy Revolution Today!
                                    </h4>
                                    <div class="banner-buttons">
                                        <Button variant="primary">View More</Button>                                    
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-xl-6 order-1 order-xl-0">
                                <div class="bannerArea__image" data-aos="fade-left" data-aos-delay="500">
                                    <img src="../images/banner_img1.png" style={{width:'100%'}}  alt="banner-image" />
                                </div>
                            </div> */}
                        </div>
                      
                    </div>
                </section>
      </Carousel.Item>
      {/* <Carousel.Item>
        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
        
        </div>

    </div>
  )
}

export default Banner