import React from "react";
import {Outlet } from "react-router-dom";
import MenuNavBar from "../component/MenuNavBar"

function Layout() {
    return (
      < >
        <MenuNavBar/>
      <Outlet />
      </>
    );
  }
  export default Layout;
  
  
  
  
  
  
  
  