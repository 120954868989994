import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

// Each project contains a single image and its corresponding details
const projects = [
  {
    image: '../images/01_gallery.jpg',
    customerName: 'Dr. Glastin',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Nemom, Thiruvannanthapuram',
    panel: 'Goutam Monperc Halcut DCR',
    inverter: 'Microtek 3.3kw'
  },
  {
    image: '../images/00_gallery.jpg',
    customerName: 'Mr. Robinson',
    capacity: '5.450kw Off Grid',
    scheme: 'MNRE Subsidy',
    location: 'Malayam, Thiruvannanthapuram',
    panel: 'Rayzon Monoperc Halcut DCR',
    inverter: 'Anchor Panasonic 5kw'
  },
  {
    image: '../images/00_gallery.jpg',
    customerName: 'Mr. Rajasekharan Nair',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Pappanamcode , Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 3.3kw'
  },
  {
    image: '../images/00_gallery.jpg',
    customerName: 'Mr. Vibhu Kumar',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Chullimanoor, Nedumangadu, Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 3.3kw'
  },
  {
    image: '../images/00_gallery.jpg',
    customerName: 'Mrs. Kala Reghukumar',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Palkulangara, Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 3.3kw'
  },
  {
    image: '../images/05_gallery.jpg',
    customerName: 'Mr. Raveendran Nair',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Anad, Nedumangadu, Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 3.3kw'
  },
  {
    image: '../images/06_gallery.jpg',
    customerName: 'Mr. Amalchanth',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Moozhy, Nedumangadu, Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 3.3kw'
  },
  {
    image: '../images/07_gallery.jpg',
    customerName: 'Mr. Satish Babu',
    capacity: '5.450kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Poojappura, Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 5kw'
  },
  {
    image: '../images/08_gallery.jpg',
    customerName: 'Mr. Sanjay Bhaskaran',
    capacity: '3.270kw On Grid',
    scheme: 'MNRE Subsidy',
    location: 'Keraladithyapuram, Mannnanthala, Thiruvananthapuram',
    panel: 'Rayzon Bifacial DCR',
    inverter: 'Anchor Panasonic 3.3kw'
  },
  // Add more project objects here
];

const MultipleImageCarousel = () => {
  // Split projects into groups of 3 for the carousel
  const chunkSize = 3;
  const projectChunks = [];
  for (let i = 0; i < projects.length; i += chunkSize) {
    projectChunks.push(projects.slice(i, i + chunkSize));
  }

  return (
    <section className='my-works-section bg-gradient-1 pb-5'>
      <Container>
        <div className='text-center pt-5'>
          <h2 className='mb-2 text-white'>Our Works</h2>
          <h6 className='text-white'>
            Discover our diverse portfolio of solar projects, showcasing our expertise and commitment to sustainable energy solutions.
          </h6>
        </div>
        <Carousel>
          {projectChunks.map((chunk, idx) => (
            <Carousel.Item key={idx}>
              <Row>
                {chunk.map((project, index) => (
                  <Col key={index} md={4} className="px-2 mb-5">
                    <Card className='p-2 h-100'>
                      <img src={project.image} alt={`slide ${idx * chunkSize + index + 1}`} className="d-block" style={{ width: '100%', height: 'auto' }} />
                      <Card.Body className='project-card'>
                        <h5 className='m-0'>{project.customerName}</h5>
                        <h6>{project.capacity}</h6>
                        {/* <hr className='m-1'/> */}
                        <ul>
                          <li><label>Scheme :</label><span> {project.scheme}</span></li>
                          <li><label>Location :</label><span> {project.location}</span></li>
                          <li><label>Panel :</label><span> {project.panel}</span></li>
                          <li><label>Inverter :</label><span> {project.inverter}</span></li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </Col>  
                ))}
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </section>
  );
};

export default MultipleImageCarousel;
