import React from "react";
import Banner from "../component/Banner";
import SectionAbout from "../component/SectionAbout";
// import SectionWhy from "../component/SectionWhy";
import SectionImportant from "../component/SectionImportant";
import Footer from "../component/Footer";
import MultipleImageCarousel from "../component/OurWorksGallery";
import SectionServices from "../component/SectionServices";
import ProductLogo from "../component/ProductLogo";
import SectionOurProducts from "../component/SectionProducts";

function Home() {
    return (
        <>
            <div className="layout-wrapper">
            <div id="home">
                <Banner />
            </div>
            </div>
            <div id="about">
                <SectionAbout />
            </div>
            <div id="why">
                <SectionImportant />
            </div>
            <div id="ourProducts">
            <SectionOurProducts/>                
            </div>
            <div id="what_we_do">
                <SectionServices />
            </div>
            {/* <div id="why">
                <SectionWhy />
            </div> */}
            
            <div id="gallery">
                <MultipleImageCarousel />
            </div>
           
            <div className='py-4 bg-white shadow-lg'>
                <ProductLogo/>
            </div> 
            <div id="contact">
            <Footer />
            </div>
        </>
    );
}

export default Home;
