import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

// Each project contains a single image and its corresponding details
const images = [
    '../images/product/01-logo.png',
    '../images/product/02-logo.png',
    '../images/product/03-logo.png',
    '../images/product/04-logo.png',
    '../images/product/05-logo.png',
    '../images/product/06-logo.png',
    '../images/product/07-logo.png',
    '../images/product/08-logo.png',
    '../images/product/09-logo.png',
    '../images/product/10-logo.png',
    '../images/product/11-logo.png',
    '../images/product/12-logo.png',
  ];

const ProductLogo = () => {
  // Split projects into groups of 3 for the carousel
  const chunkSize = 6;
  const projectChunks = [];
  for (let i = 0; i < images.length; i += chunkSize) {
    projectChunks.push(images.slice(i, i + chunkSize));
  }

  return (
    <section>
      <Container>
        <h2 className='mb-2 text-center'>Our Brands</h2>
        <h6 className='text-center'>AVS Solar partners with leading solar brands, offering products like Mono PERC Half-cut, Bifacial, and Topcon panels for unmatched efficiency and performance. </h6>
          {projectChunks.map((chunk, idx) => (
            <div key={idx} className='mt-4'>
              <Row>
                {chunk.map((images, index) => (
                  <Col key={index} md={2} xs={6} className="px-2">
                    <div className='p-4 d-flex align-items-center jusify-content-center' style={{height:'100px'}}>
                      <img src={images} alt={`slide ${idx * chunkSize + index + 1}`} className="d-block" style={{ width: '124px', height: 'auto' }} />
                      
                    </div>
                  </Col>  
                ))}
              </Row>
            </div>
          ))}
      </Container>
    </section>
  );
};

export default ProductLogo;
