import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Figure from 'react-bootstrap/Figure';
import AOS from 'aos';
import 'aos/dist/aos.css';


function SectionAbout() {
  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-in-out',
      once: false, // Set to false to allow animations every time you scroll to the section
    });
  }, []);
  return (
    <section>
        <Container className="">
        <div className='text-center py-4'>
          <h2 className='fw-bold mb-2'>About Us</h2>
          <h6 className=''>
          Empowering a Greener Future with Solar Solutions.
          </h6>
        </div>
    <Row>
    <Col xl={6} data-aos="fade-right">
      <div>
      
      <p className="mb-1 lh-lg h6 fw-normal">At AVS Solar, we are committed to revolutionizing the way energy is harnessed and utilized. As a leading provider of solar solutions, we specialize in designing, installing, and maintaining state-of-the-art solar power systems for residential, commercial, and industrial clients.</p>
      <p className="mb-1 lh-lg h6 fw-normal">Our mission at AVS Solar is to empower individuals and businesses to embrace clean, renewable energy solutions that not only reduce carbon emissions but also lower energy costs and promote sustainability. We strive to make solar energy accessible to everyone, driving positive change for our planet and future generations.</p>
      <p className="lh-lg h6 fw-normal">We strive to make solar energy accessible to everyone, driving positive change for our planet and future generations. Through innovation and dedication, AVS Solar is at the forefront of the clean energy movement, helping to create a healthier environment and a brighter tomorrow. Join us in our mission to harness the power of the sun and make a lasting impact on the world.</p>
      </div>
    </Col>
    <Col  xl={6} data-aos="fade-left"><Figure style={{width:'100%', height:'500px', overflow:'hidden', borderRadius:'15px '}}><Figure.Image style={{width:'100%'}} src="../images/banner_img1.png"   alt="banner-image" /></Figure></Col>
  </Row>
  </Container>
   
  </section>
  )
}

export default SectionAbout