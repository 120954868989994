import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';

function SectionServices() {
  const [services, setServices] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 600,
      easing: 'ease-in-out',
      once: false, // Set to false to allow animations every time you scroll to the section
    });
  }, []);

  useEffect(() => {
    // Simulating data fetch
    setTimeout(() => {
      setServices([
        {
          title: 'Solar System Design and Installation',
          text: 'Our skilled technicians ensure flawless installations, managing panel mounting, wiring systems, and grid connections seamlessly',
          img: '../images/img03.jpg',
        },
        {
          title: 'Monitoring and Maintenance',
          text: 'AVS Solar provides monitoring systems and regular maintenance to optimize solar system efficiency and longevity.',
          img: '../images/img04.jpg',
        },
        {
          title: 'Customer Training and Support',
          text: 'We offer comprehensive training and dedicated support to maximize your solar investment.',
          img: '../images/img05.jpg',
        },
      ]);
      AOS.refresh();  // Refresh AOS after new services are loaded
    }, 1000);
  }, []);

  return (
    <section>
      <Container>
        <div className='text-center py-3'>
          <h6 className='text-primary text-uppercase'>Services</h6>
          <h2 className='fw-bold mb-2'>Your Partner in Solar Energy Solutions</h2>
          <h6>At AVS Solar, we offer a comprehensive range of services aimed at providing sustainable energy solutions...</h6>
        </div>
        <Row className='mb-5'>
          {services.map((service, index) => (
            <Col
              key={index}
              xl={4}
              data-aos="fade-up"
              data-aos-delay={index * 100} // Delay by 100ms for each item
            >
              <Card className='w-100 h-100'>
                <Card.Img variant="top" src={service.img} />
                <Card.Body>
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default SectionServices;
